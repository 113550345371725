import Logo from "../assets/logo.svg"
import '../styles/App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={Logo} className="App-logo" alt="logo" />
        <img
          src="https://firebasestorage.googleapis.com/v0/b/beybladeespace-86bb4.appspot.com/o/site%2Fbanji-chibi.webp?alt=media"
          alt="banji chibi qui tourne"
          className="Banji"
        />
        <p className="desc">
          Soon
        </p>
      </header>
    </div>
  );
}

export default App;
